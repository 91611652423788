import React from 'react'
import '../website/Home.css';
import {
    BrowserView,
    MobileView
  } from "react-device-detect";
import "./contact.css";

export default function contact()
{

    function startButton() // Zur Startseite gehen
    {
        window.location.replace("../");
    }

    function productsButton() // Zur Seite "Produkte" gehen
    {
        window.location.replace("../products");
    }

    function contactButton() // Zur Seite "Kontakt" gehen
    {
        window.location.replace("../contact");
    }


    return (
        <div>
            <BrowserView>
                <div className="Header Center">
                    <div id="teil1">
                        
                    </div>

                    <div id="teil2">
                        <button className="HeaderButton" onClick={startButton}>Startseite</button>
                        <button className="HeaderButton" onClick={productsButton}>Produkte</button>
                        <button className="HeaderButton" onClick={contactButton}>Kontakt</button>
                    </div>

                    <div>
                        <p className="TextAbstand">Kontaktdaten</p>

                        <div className="Devider"/>

                        <div className="ContactBoxHolder">
                                <h className="ContactHeader">Kontaktdaten von unserer Produktion</h>
                                <div className="ContactHeaderDevider"/>
                                <div className="HeadlineDiv"/>
                                <h className="Information">Zum Reiherhorst 2</h>
                                <div/>
                                <h className="Information">21435 Stelle</h>
                                <div className="HeadlineDiv"/>
                                <h className="Information">Tel.: 04174 / 59890</h>
                                <div className="HeadlineDiv"/>
                                <h className="Information">E-Mail: info@antagon24.de</h>
                            
                        </div>

                        
                    </div>
                </div>
          </BrowserView>

          <MobileView>
            <div>
                <div>
                    <div className="MobileLogo"/>
                        <div className="MobileSubheader">
                        <button className="MobileSubheaderButton" onClick={startButton}>Startseite</button>
                        <button className="MobileSubheaderButton" onClick={productsButton}>Produkte</button>
                        <button className="MobileSubheaderButton" onClick={contactButton}>Kontakt</button>
                    </div>

                    <div className="Center">
                    <p className="TextAbstand">Kontaktdaten</p>

                        <div className="Devider"/>

                        <div className="ContactBoxHolder" style={{width: "100vw", borderRadius: "0"}}>
                                <h className="ContactHeader">Kontaktdaten von unserer Produktion</h>
                                <div className="ContactHeaderDevider"/>
                                <div className="HeadlineDiv"/>
                                <h className="Information">Zum Reiherhorst 2</h>
                                <div/>
                                <h className="Information">21435 Stelle</h>
                                <div className="HeadlineDiv"/>
                                <h className="Information">Tel.: 04174 / 59890</h>
                                <div className="HeadlineDiv"/>
                                <h className="Information">E-Mail: info@antagon24.de</h>
                            
                        </div>

                        
                    </div>
                </div>
            </div>
          </MobileView>
        </div>
    );
}