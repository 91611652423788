import React, { useState } from 'react';

import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import { SketchPicker } from 'react-color';

import SwipeableViews from 'react-swipeable-views';

import './Product.css';
import '../Home.css';
import './ProductMobile.css'

import axios from 'axios';

import fabric from 'fabric';

import { BrowserView, deviceType, MobileView } from 'react-device-detect';

import DeviceOrientation, { Orientation } from 'react-screen-orientation'

import { PayPalButton } from "react-paypal-button-v2";

export default function productBox1()
{

  const toHome = () => 
  {
    window.location.replace("../../");
  }
  const toProducts = () => 
  {
    window.location.replace("../../products");
  }

  const toContact = () => 
  {
    window.location.replace("../../contact");
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { selectedObjects, editor, onReady } = useFabricJSEditor({ defaultStrokeColor: 'black' })

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [text, setText] = useState("");

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [strokeColor, setStrokeColor] = useState("");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [fillColor, setFillColor] = useState("");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [key, setKey] = useState('home');

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [mview, setMview] = useState(0);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [id, setID] = useState(0);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [iindex, setIIndex] = useState(0);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [mobmview, setMobMview] = useState(0);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [vCard, setVcard] = useState(true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [vertical, setVertical] = useState(true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [editing, setEditing] = useState(false);
  
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [ordering, setOrdering] = useState(false);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [paying, setPaying] = useState(false);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [mobileToolVisible, setMobileToolVisible] = useState(true);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const hiddenFileInput = React.useRef(null);

  const fileinputClick = event => 
  {
    hiddenFileInput.current.click();
  };

  const onAddCircle = () => 
  {
    editor.addCircle();
  };
  const onAddRectangle = () => {
    editor.addRectangle();
  };
  const onAddLine = () => {
    editor.addLine();
  };
  const onDeleteSelected = () => {
    // eslint-disable-next-line no-unused-expressions
    editor?.deleteSelected();
  }

  const onAddText = () => {
    if (selectedObjects?.length) 
    {
      return editor?.updateText(text);
    }
    

    // eslint-disable-next-line no-unused-expressions
    editor?.addText("Text");

    
  }

  const onAddImage = (picture) => 
  {

    fabric.fabric.Image.fromURL(picture, function(myImg) {
      var img = myImg.set({ left: 100, top: 100});

      img.scaleToHeight(250);
      img.scaleToWidth(250);
      editor.canvas.add(img); 
    });

  }

  const getSVG = () => 
  {
    var svg = editor.canvas.toSVG();

    var svgdata = svg.split(' '); // Splits Vectorgraphic into single words

    var width = svgdata[14]; // Read width from SVG
    var height = svgdata[15]; // Read height from SVG

    if(vertical)
    {
      svg = svg.replace(width, 'width="118mm"'); // Scale Up SVG Width to the exact mm params.
      svg = svg.replace(height, 'height="380mm"'); // Scale Up SVG Height to the exact mm params.
    }
    else
    {
      svg = svg.replace(width, 'width="380mm"'); // Scale Up SVG Width to the exact mm params.
      svg = svg.replace(height, 'height="118mm"'); // Scale Up SVG Height to the exact mm params.
    }

    svg = svg.replace("<desc>Created with Fabric.js 4.2.0</desc>", "<desc>Exported from StyleYourBox</desc>");  // Replaces the FabricJS comment with StyleYourBox comment

    return svg;
  }

  

  const onExport = () => 
  {
    var svg = getSVG();
 
    const element = document.createElement("a");
    const file = new Blob([svg], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = "download.svg";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  const onPay = () => 
  {
    setOrdering(true)

    const svgData = getSVG();
    console.log(svgData);
  }

  const onImageChange = event => 
  {
    if (event.target.files && event.target.files[0]) 
    {
      const data = new FormData();
      data.append('file', event.target.files[0]);

      axios.post('http://styleyourbox.com:3001/api/upload', data).then((res) => {

        onAddImage("http://styleyourbox.com/api/server" + res.data.filePath);
      });
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [name, setName] = useState("");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [comp, setComp] = useState("");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [mail, setMail] = useState("");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [address, setAddress] = useState("");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [plz, setPlz] = useState("");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [location, setLocation] = useState("");

  const post = () =>
  {
    onExport();

    axios({
      method: 'post',
      url: 'http://localhost:3001/api/order',
      data: {
        dataProduct: "Weinbox",
        dataName: name,
        dataComp: comp,
        dataMail: mail,
        dataAddress: address,
        dataPlz: plz,
        dataLocation: location,
        dataDelivery: (vCard)? "Standard" : "Premium",
        dataVector: getSVG()
      }
    });
  }
  

  // React.Js Webdesign Code
  return (
    <div>
      <BrowserView>
        <div className="Center">
        <div>
          <div className="Header Center">
            <div id="teil1">
                      
            </div>
              <div id="teil2">
                <button className="HeaderButton" onClick={toHome}>Startseite</button>
                <button className="HeaderButton" onClick={toProducts}>Produkte</button>
                <button className="HeaderButton" onClick={toContact}>Kontakt</button>
              </div>
            </div>
          </div>

          {(()=> {

            if(!editing)
            {
              return(
                <div className='popup'> {/* Choose Editor Mode | Popup */}
                  <div className='popup_inner' style={{bottom: "47.5vh"}}>
                    <h className="cEditor">Bearbeitungsmodus auswählen</h><div/>

                    <div className="cModeHold">
                      <div className="cCmode">
                        <h style={{fontSize: "2.5vh"}}>Hochformat</h><div/>

                        <button className="cEditorMode" onMouseDown={() => setVertical(true)} id={vertical? "active" : ""}>
                          <div className="grHo"/>
                        </button>
                      </div>

                      <div className="cCmode">
                        <h style={{fontSize: "2.5vh"}}>Querformat</h><div/>

                        <button className="cEditorMode" onMouseDown={() => setVertical(false)} id={vertical? "" : "active"}>
                          <div className="grVe"/>
                        </button>
                      </div>
                    </div>

                    <button className="cConti" onClick={
                      () =>
                      {
                        setEditing(true);
                      }
                    }>Weiter zur Bearbeitung</button>

                  </div>
                </div>
              ); 
            }

          })()}

          <div className="prod_sp">
            <div id="f">

              <div className="v_menu_tle">
                <h id="h1_v">Weinbox</h>
                <div/>
                <h id="ed1_v">380mm x 118mm</h>
              </div>

              <div className="v_menu">
                <div id="t_view"> 
                  {(() => { if(mview == 0) { return(<button className="t_view_btn" id="on" onMouseDown={() => setMview(0)}/>); } else { return(<button className="t_view_btn" id="off" onMouseDown={() => setMview(0)}/>); } })()}
                  {(() => { if(mview == 1) { return(<button className="t_view_btn" id="on" onMouseDown={() => setMview(1)}/>); } else { return(<button className="t_view_btn" id="off" onMouseDown={() => setMview(1)}/>); } })()}
                  <button className="fin_btn" onClick={onPay}/>
                </div>
                
                <div id="t_menu">
                  {(() => {
                    if(mview == 0)
                    {
                      return(
                        <div>

                          <h style={{visibility: "hidden"}}>Weinbox</h>

                          <div>
                            <h style={{marginTop: "1vh", fontSize: "3vh"}}>Bild hochladen</h>
                          </div>

                          <input
                            type="file"
                            ref={hiddenFileInput}
                            onChange={onImageChange}
                            style={{display: 'none'}}
                          />

                          <button className="uploadPic" onClick={fileinputClick}>+</button>

                        </div>
                      );
                    }
                    else if(mview == 1)
                    {
                      return(
                        <div>

                          <h style={{visibility: "hidden"}}>Weinbox</h>

                          <div>
                            <h style={{marginTop: "1vh", fontSize: "3vh"}}>Text hinzufügen</h>
                          </div>

                          <button className="t_3_btn" onClick={onAddText} style={{marginTop: "2.5vh"}}>Text hinzufügen</button>
                        </div>
                      );
                    }
                  })()}
                </div>
              </div>
            </div>

            <div className="fTopCanv">

            </div>
            
            <div id="s" className="fCanv">
              {(() => {
                if(editing)
                {
                  if(vertical)
                    return(
                      <FabricJSCanvas id="fabriccanvas" className="PreviewBox_V" onReady={onReady}/>
                    );
                  else
                    return(
                      <FabricJSCanvas id="fabriccanvas" className="PreviewBox_H" onReady={onReady}/>
                    );
                }
              })()}
            </div>
          </div>

          {/*<button onClick={onExport}>Export</button>
            <button onClick={onDeleteSelected}>Delete</button>
            <button onClick={onAddText}>Text</button>
            <button onClick={onAddRectangle}>Viereck</button>
            <button onClick={onAddCircle}>Kreis</button>

            <input
              type="file"
              ref={hiddenFileInput}
              onChange={onImageChange}
              style={{display: 'none'}}
            />

            <button onClick={fileinputClick}>
              Upload a file
            </button>*/}


          
          
          {(() => { // Show Payment Popup
            if(ordering)
            {
              return(
                <div className='popup'>
                    <div className='popup_inner' style={{bottom: paying? "0%" : "25%", borderRadius: paying? "0vh" : "0 0 2vh 2vh"}}>
                        <div>
                            
                            {(() => {
                              if(!paying)
                              {
                                return(
                                  <div>
                                    <div className="bIheader">
                                      <button className="cBtn" onClick={() => setOrdering(false)}>Schließen</button>
                                    </div>

                                    <div className="infContainer">
                                      <div className="infH">
                                        <h className="infP" id="high">*</h>
                                        <h className="infLabel">Name:</h>
                                        <input className="infInput"
                                          value={name}
                                          onChange={e => setName(e.target.value)}>
                                        </input>
                                      </div>

                                      <div className="infH">
                                        <h className="infP" id="low">*</h>
                                        <h className="infLabel">Firma:</h>
                                        <input className="infInput"
                                          value={comp}
                                          onChange={e => setComp(e.target.value)}>
                                        </input>
                                      </div>

                                      <div className="infH">
                                        <h className="infP" id="high">*</h>
                                        <h className="infLabel">E-Mail:</h>
                                        <input className="infInput"
                                          value={mail}
                                          onChange={e => setMail(e.target.value)}>
                                        </input>
                                      </div>

                                      <div className="infH">
                                        <h className="infP" id="high">*</h>
                                        <h className="infLabel">Straße und Nummer:</h>
                                        <input className="infInput"
                                          value={address}
                                          onChange={e => setAddress(e.target.value)}>
                                        </input>
                                      </div>

                                      <div className="infH">
                                        <h className="infP" id="high">*</h>
                                        <h className="infLabel">PLZ:</h>
                                        <input className="infInput"
                                          value={plz}
                                          onChange={e => setPlz(e.target.value)}>
                                        </input>
                                      </div>

                                      <div className="infH">
                                        <h className="infP" id="high">*</h>
                                        <h className="infLabel">Ort:</h>
                                        <input className="infInput"
                                          value={location}
                                          onChange={e => setLocation(e.target.value)}>
                                        </input>
                                      </div>

                                    </div>

                                    <div className="vBar">

                                    <button className="vItem" id={vCard? "vSelected" : "vUnselected"} onMouseDown={() => setVcard(true)}>
                                      <h>DHL</h>
                                      <div/>
                                      <h>Standardversand</h>
                                      <div/>
                                      <h>3,99€</h>
                                    </button>

                                    <button className="vItem" id={vCard? "vUnselected" : "vSelected"} onMouseDown={() => setVcard(false)}>
                                      <h>DHL</h>
                                      <div/>
                                      <h>Expressversand</h>
                                      <div/>
                                      <h>15,99€</h>
                                    </button>

                                    </div>

                                    <div className="bIfooter">
                                      <button className="nBtn" onClick={() => {

                                        setPaying(true);

                                        post();

                                      }}>Weiter zur Zahlung</button>
                                    </div>
                                  </div>
                                );
                              }
                              else
                              {
                                return(
                                  <div>
                                    <div className="bIheader">
                                      <button className="bBtn" onClick={() => setPaying(false)}>Zurück</button>
                                    </div>

                                    <div className="paypalHold">
                                      <div className="paypalModule"><PayPalButton
                                        amount={vCard? "28.48" : "40.48"}
                                        
                                        onSuccess={(details, data) => {

                                            post();
                                    
                                            // OPTIONAL: Call your server to save the transaction
                                            return fetch("/paypal-transaction-complete", {
                                                    method: "post",
                                                    body: JSON.stringify({
                                                      orderID: data.orderID
                                                    })
                                                });
                                            }}


                                            options={{
                                                clientId: "ATc_M2DayqL9ArVXDMCzOcUAzFUKAovy-qNEdsc2U4L1VA7jQZWIzyTifeg3eDhRpNjSH4aQT_kEWhEd",
                                                currency:"EUR" // European Currency Patch
                                            }}
                                        
                                        /></div>
                                    </div>
                                  </div>
                                );
                              }

                            })()}

                        </div>
                    </div>
                </div>
              );
            }
          })()}
          
        </div>
      </BrowserView>


      <MobileView>
          <DeviceOrientation>

            <Orientation orientation='portrait' alwaysRender={false}>

              <div>
                <div className="MobileLogo"/>
                <div className="MobileSubheader" style={{boxShadow: "0 0 0 0 #ffffff"}}>
                  <button className="MobileSubheaderButton" onClick={toHome}>Startseite</button>
                  <button className="MobileSubheaderButton" onClick={toProducts}>Produkte</button>
                  <button className="MobileSubheaderButton" onClick={toContact}>Kontakt</button>
                </div>
              </div>

              {(() => {
                  if(mobileToolVisible)
                  {
                    return(
                      <div>
                        <SwipeableViews index={mobmview} onChangeIndex={e => setMobMview(e)}>
                          <div className="m_tool_1">
                            <div style={{width: "20vh", marginLeft: "auto", marginRight: "auto", textAlign: "center"}}>
                              <div style={{height: "1vh"}}/>
                              <h style={{fontSize: "3vh"}}>Bild hochladen</h>
                              <input
                                type="file"
                                ref={hiddenFileInput}
                                onChange={onImageChange}
                                style={{display: 'none'}}
                              />
                              <button className="uploadPic_m" onClick={fileinputClick}>+</button>
                            </div>
                          </div>
                          <div className="m_tool_2">

                            <div style={{width: "100%", marginLeft: "auto", marginRight: "auto", textAlign: "center"}}>
                              <div style={{height: "1vh"}}/>
                              <h style={{fontSize: "3vh"}} >Text hinzufügen</h>
                              
                              <button className="m_txtBtn_p" onClick={onAddText}>Text hinzufügen</button>
                            </div>
                            
                          </div>
                          <div className="m_tool_3">
                            <div style={{width: "100%", marginLeft: "auto", marginRight: "auto", textAlign: "center"}}>
                              <div style={{height: "1vh"}}/>
                              <h style={{fontSize: "3vh"}}>Konfiguration abschließen</h>
                                
                              <button className="m_finBtn_p" onClick={() => setOrdering(true)}>Weiter zum Kaufbildschirm</button>
                            </div>
                          </div>
                        </SwipeableViews>

                        <div className="m_tool_footer">
                          {(() => {

                            const pointList = []

                            for(var i = 0; i < 3; i++)
                            {

                              var btncolor = "#ffffff"

                              if(mobmview == i) btncolor = "#516385";

                              pointList.push(
                                <button style={{height: "2vh", width: "2vh",
                                                marginTop: "0.25vh", marginBottom: "0.25vh",
                                                marginLeft: "1vh", marginRight: "0.5vh",
                                                borderRadius: "1vh",
                                                border: "none",
                                                backgroundColor: btncolor}}>
                                </button>
                              );
                            }

                            return(
                              <div >
                                <div style={{float: "left"}}>
                                  {pointList}
                                </div>

                                <h style={{marginLeft: "0.5vh", color: "#a6a6a6"}}>Wische nach links oder rechts</h>
                              </div>
                            );

                          })()}
                        </div>
                      </div>
                      
                    );
                  }
                })()}
              
              {(() => {
                if(!editing) setEditing(true);
              })()}

              <div style={{textAlign: "center"}}>
                
              <div/>
                <button className="m_tool_toggle" onClick={() => {
                  setMobileToolVisible(!mobileToolVisible);
                }}>{mobileToolVisible? "Toolbox schließen" : "Toolbox öffnen"}</button>
              </div>

              {(() => {
                if(editing)
                {
                  if(vertical)
                  {
                    return(
                      <div>
                        <FabricJSCanvas id="fabriccanvas" className="m_pBox_v" onReady={onReady}/>
                      </div>
                    );
                  }
                  else
                  {
                    return(
                      <div>
                        
                      </div>
                    );
                  }
                }
              })()}

              {(() => {

                if(ordering)
                {
                  if(paying)
                  {
                    return(
                      <div className='popup'>
                          <div className='popup_inner' style={{left: "5%", right: "5%", bottom: "5%", top: "5%", borderRadius: "2vh", textAlign: "center"}}>
                            <div className="bIheader">
                              <button className="cBtn" onClick={() => setPaying(false)} style={{width: "55%", borderRadius: "2vh 0 10vh 0"}}>Zurück</button>
                            </div>

                            <div className="paypalHold" style={{maxWidth: "90%"}}>
                              <div className="paypalModule"><PayPalButton
                                  amount="24.49"
                                  // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                                  onSuccess={(details, data) => {

                                    post();
                                    
                                    // OPTIONAL: Call your server to save the transaction
                                    return fetch("/paypal-transaction-complete", {
                                      method: "post",
                                      body: JSON.stringify({
                                        orderID: data.orderID
                                      })
                                    });
                                  }}
                                  options= {{
                                    clientId: "ATc_M2DayqL9ArVXDMCzOcUAzFUKAovy-qNEdsc2U4L1VA7jQZWIzyTifeg3eDhRpNjSH4aQT_kEWhEd"
                                    ,currency:"EUR" // European Currency Patch
                                  }}/></div>
                            </div>
    
                          </div>
                        </div>
                    );
                  }
                  else
                  {
                    return(
                      <div className='popup'>
                          <div className='popup_inner' style={{left: "5%", right: "5%", bottom: "5%", top: "5%", borderRadius: "2vh", textAlign: "center"}}>
                            <div className="bIheader">
                              <button className="cBtn" onClick={() => setOrdering(false)} style={{width: "55%", borderRadius: "2vh 0 10vh 0"}}>Schließen</button>
                            </div>
    
                            <div>
                              <h style={{fontSize: "2.5vh"}}>Name:</h>
                              <input style={{width: "90%", fontSize: "2.5vh"}}
                                value={name}
                                onChange={e => setName(e.target.value)}>
                              </input>

                              <div style={{height: "1vh"}}/>
                            </div>
    
                            <div>
                              <h style={{fontSize: "2.5vh"}}>Firma:</h>
                              <div style={{height: "1vh"}}/>
                              <input style={{width: "90%", fontSize: "2.5vh"}}
                                value={comp}
                                onChange={e => setComp(e.target.value)}>
                              </input>
                              <div style={{height: "1vh"}}/>
                            </div>
    
                            <div>
                              <h style={{fontSize: "2.5vh"}}>E-Mail:</h>
                              <div style={{height: "1vh"}}/>
                              <input style={{width: "90%", fontSize: "2.5vh"}}
                                value={mail}
                                onChange={e => setMail(e.target.value)}>
                              </input>
                              <div style={{height: "1vh"}}/>
                            </div>
    
                            <div>
                              <h style={{fontSize: "2.5vh"}}>Straße und Nummer:</h>
                              <div style={{height: "1vh"}}/>
                              <input style={{width: "90%", fontSize: "2.5vh"}}
                                value={address}
                                onChange={e => setAddress(e.target.value)}>
                              </input>
                              <div style={{height: "1vh"}}/>
                            </div>
    
                            <div>
                              <h style={{fontSize: "2.5vh"}}>PLZ:</h>
                              <div style={{height: "1vh"}}/>
                              <input style={{width: "90%", fontSize: "2.5vh"}}
                                value={plz}
                                onChange={e => setPlz(e.target.value)}>
                              </input>
                              <div style={{height: "1vh"}}/>
                            </div>
    
                            <div>
                              <h style={{fontSize: "2.5vh"}}>Ort:</h>
                              <div style={{height: "1vh"}}/>
                              <input style={{width: "90%", fontSize: "2.5vh"}}
                                value={location}
                                onChange={e => setLocation(e.target.value)}>
                              </input>
                              <div style={{height: "1vh"}}/>
                            </div>
    
                            <div>
                              <div className="m_beltDelivery">
                                <button className="m_deliveryChoice" id={vCard? "bselected" : "bunselected"} onClick={() => setVcard(true)}>
                                  DHL Standardversand
                                </button>
                              </div>
    
                              <div className="m_beltDelivery">
                                <button className="m_deliveryChoice" id={vCard? "bunselected" : "bselected"} onClick={() => setVcard(false)}>
                                  DHL Premiumversand
                                </button>
                              </div>
                            </div>
    
                            <button className="m_buyConBtn" onClick={() => setPaying(true)}>
                              Kauf abschließen (24,49€)
                            </button>
    
                          </div>
                        </div>
                    );
                  }
                }

              })()}

              



            </Orientation>

            <Orientation orientation='landscape' alwaysRender={false}>

              <div style={{textAlign: "center"}}>
                <h style={{fontSize: "3vw"}}>Bitte drehe das Telefon zurück zum Hochformat</h>
              </div>
              
            </Orientation>

          </DeviceOrientation>
      </MobileView>
    </div>
  );
}