import React, { useState } from 'react';
import './pay.css';
import './Home.css';
import {
    BrowserView,
    MobileView
  } from "react-device-detect";

import { PayPalButton } from "react-paypal-button-v2";


export default class Popup extends React.Component 
{
    render() 
    {
        return (
            <div className='popup'>
                <div className='popup_inner'>
                    
                </div>
            </div>
        );
    }
}

/*export default function pay()
{

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [name, setName] = useState("");
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [company, setCompany] = useState("");
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [address, setAddress] = useState("");
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [location, setLocation] = useState("");
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [plz, setPlz] = useState("");
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [email, setEmail] = useState("");

    

    const checkWarning = () =>
    {
        if(name == "") alert("Pflichtfelder (mit * markiert) müssen ausgefüllt werden.");

        if(address == "") alert("Pflichtfelder (mit * markiert) müssen ausgefüllt werden.");

        if(location == "") alert("Pflichtfelder (mit * markiert) müssen ausgefüllt werden.");

        if(plz == "") alert("Pflichtfelder (mit * markiert) müssen ausgefüllt werden.");

        if(email == "") alert("Pflichtfelder (mit * markiert) müssen ausgefüllt werden.");
    }

    const paymentSuccessful = () =>
    {
        GetFeedback();

        if(name == "") alert("Pflichtfelder (mit * markiert) müssen ausgefüllt werden.");
        console.log(name);
        
        if(company != "")
            console.log(company);

        if(address == "") alert("Pflichtfelder (mit * markiert) müssen ausgefüllt werden.");
        console.log(address);

        if(location == "") alert("Pflichtfelder (mit * markiert) müssen ausgefüllt werden.");
        console.log(location);

        if(plz == "") alert("Pflichtfelder (mit * markiert) müssen ausgefüllt werden.");
        console.log(plz);

        if(email == "") alert("Pflichtfelder (mit * markiert) müssen ausgefüllt werden.");
        console.log(email);
    }

    

    return (
        <div>
            <BrowserView>
                <div>
                    <div className="Header Center">
                        <div id="teil1"/>
                    </div>
                    
                    <h className="payTitle Center">Kaufen</h>

                    <div className="PayBox">
                        <div>
                            <div className="CustomDevider"/>
                            <h className="Star">*</h>
                            <h className="Label">Name: </h>
                            <input className="BetterInput Right" value={name} onChange={e => setName(e.target.value)}></input>

                            <div className="CustomDevider"/>
                            <h className="NoStar">*</h>
                            <h className="Label">Firma: </h>
                            <input className="BetterInput Right" value={company} onChange={e => setCompany(e.target.value)}></input>

                            <div className="CustomDevider"/>
                            <h className="Star">*</h>
                            <h className="Label">Straße & Nr.: </h>
                            <input className="BetterInput Right" value={address} onChange={e => setAddress(e.target.value)}></input>

                            <div className="CustomDevider"/>
                            <h className="Star">*</h>
                            <h className="Label">Ort: </h>
                            <input className="BetterInput Right" value={location} onChange={e => setLocation(e.target.value)}></input>

                            <div className="CustomDevider"/>
                            <h className="Star">*</h>
                            <h className="Label">PLZ: </h>
                            <input className="BetterInput Right" value={plz} onChange={e => setPlz(e.target.value)}></input>

                            <div className="CustomDevider"/>
                            <h className="Star">*</h>
                            <h className="Label">E-Mail: </h>
                            <input className="BetterInput Right" value={email} onChange={e => setEmail(e.target.value)}></input>

                            <div className="CustomDevider"/>
                            <button className="Center" onClick={paymentSuccessful}>Simulate Payment</button>
                        </div>
                        
                        <div className="CustomDevider2"/>
                        <div className="PayPal Center">
                            <PayPalButton
                                on
                                amount="24.49"
                                // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                                onSuccess={(details, data) => {
                                    alert("Transaction completed by " + details.payer.name.given_name);
                            
                                    // OPTIONAL: Call your server to save the transaction
                                    return fetch("/paypal-transaction-complete", {
                                        method: "post",
                                        body: JSON.stringify({
                                        orderID: data.orderID
                                        })
                                    });
                                }}
                                options={{
                                    clientId: "ATc_M2DayqL9ArVXDMCzOcUAzFUKAovy-qNEdsc2U4L1VA7jQZWIzyTifeg3eDhRpNjSH4aQT_kEWhEd"
                                    ,currency:"EUR" // European Currency Patch
                                }}
                                
                            />
                        </div>
                    </div>

                    

                </div>
            </BrowserView>

            <MobileView>
                <div>
                    <div>
                        <div className="MobileLogo"/>
                    </div>
                </div>
            </MobileView>
        </div>
    );
}*/