import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import admin from './website/admin';
import home from './website/home';
import products from './website/products';
import error from './error';
import product1 from './website/products/productBox1';
import contact from './website/contact';
import pay from './website/pay';

function App() 
{  

  return (
    <BrowserRouter>
        <div>
          <Switch>
            <Route path="/" component={home} exact/>
            <Route path="/products/weinbox" component={product1}/>
            <Route path="/products" component={products}/>
            <Route path="/contact" component={contact}/>
            <Route path="/admin" component={admin}/>
            <Route component={error}/>
           </Switch>
        </div> 
      </BrowserRouter>
  );
}

export default App;
