import React, { useState } from 'react';
import './admin.css';
import {
  BrowserView,
  MobileView
} from "react-device-detect";


function Admin() 
{

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [login, setLogin] = useState(false);

  // React.Js Webdesign Code
  return(<div>

    <BrowserView>
      {(() => {

        if(login)
        {

        }
        else
        {
          return(
            <div>

            </div>
          );
        }

      })()}
    </BrowserView>

    <MobileView>
      Mobile View is not Available
    </MobileView>

  </div>);

    
}

export default Admin;
