import React from 'react';
import './error.css';
import {
    BrowserView,
    MobileView
  } from "react-device-detect";



function Error() 
{
    //Backend-Code für das Design

    function goHome(e)
    {
        window.location.replace("../")
    }


    // React.Js Webdesign Code
    return (
        <div>
            <BrowserView>
                <div className="Error">
                    <h className="BigText">StyleYourBox.com</h>
                    <div/>
                    <h className="NormalText">Diese Seite wurde nicht gefunden.</h>
                    <div/>
                    <button className="HomeButton" onClick={goHome}>Zurück zur Homepage</button>
                </div>
            </BrowserView>

            <MobileView>
                <div className="MobileError">
                    <h className="MobileBigText">StyleYourBox.com</h>
                    <div/>
                    <h className="MobileNormalText">Diese Seite wurde nicht gefunden.</h>
                    <div/>
                    <button className="MobileHomeButton" onClick={goHome}>Zurück zur Homepage</button>
                </div>
            </MobileView>
        </div>
  );
}

export default Error;
