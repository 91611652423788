import React, { useState } from 'react';
import './Home.css';
import {
    BrowserView,
    MobileView
  } from "react-device-detect";
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import axios from 'axios';



function Home() 
{
    //Backend-Code für das Design
    function startButton()
    {
        window.location.replace("../");
    }

    function productsButton()
    {
        window.location.replace("../products");
    }

    function contactButton()
    {
        window.location.replace("../contact");
    }

    const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

    const [index, setIndex] = useState(0);

    // React.Js Webdesign Code
    return (<div>

        <BrowserView>
            <div>

                {(() => {
                    axios.get('http://localhost:3001/api').then(function (response) {
                        console.log(response);
                    })
                })()}

                <div className="Header Center">
                    <div id="teil1">
                        
                    </div>

                    <div id="teil2">
                        <button className="HeaderButton" onClick={startButton}>Startseite</button>
                        <button className="HeaderButton" onClick={productsButton}>Produkte</button>
                        <button className="HeaderButton" onClick={contactButton}>Kontakt</button>
                    </div>
                </div>


                <div style={{textAlign: "center"}}>
                    <h style={{visibility: "hidden"}}>StyleYourBox by Antagon24</h>
                    <div>
                        <b style={{fontSize: "5.5vh", margin: "5vh"}}>Willkommen bei StyleYourBox</b>
                    </div>
                </div>

                <div className="dhomeImageHolder">

                    <div style={{width: "24.5vw", height: "70vh", float: "left"}}>
                        <div className="dprevBox1"/>
                    </div>

                    <div style={{width: "24.5vw", height: "70vh", float: "left"}}>
                        <div className="dprevBox2"/>
                    </div>

                    <div style={{width: "24.5vw", height: "70vh", float: "left"}}>
                        <div className="dprevBox3"/>
                    </div>

                    <div style={{width: "24.5vw", height: "70vh", float: "left"}}>
                        <div className="dprevBox4"/>
                    </div>

                </div>

                <div style={{textAlign: "center"}}>
                    <button style={{width: "25vw", height: "5vh", fontSize: "1vw", border: "none", borderRadius: "4vh", backgroundColor: "#9ca5ff"}} onClick={() => window.location.replace("../products/weinbox")}>Gestalte jetzt deine eigene Weinbox</button>
                </div>

                <div style={{height: "10vh", backgroundColor: "#3d3d3d", marginTop: "10vh"}}>

                </div>

            </div>
        </BrowserView>

        <MobileView>
            <div>
                <div>
                    <div className="MobileLogo"/>
                    <div className="MobileSubheader">
                        <button className="MobileSubheaderButton" onClick={startButton}>Startseite</button>
                        <button className="MobileSubheaderButton" onClick={productsButton}>Produkte</button>
                        <button className="MobileSubheaderButton" onClick={contactButton}>Kontakt</button>
                    </div>
                </div>

                <div style={{textAlign: "center"}}>
                    <h style={{visibility: "hidden"}}>StyleYourBox by Antagon24</h>
                    <div>
                        <b style={{fontSize: "5.5vh", margin: "5vh"}}>Willkommen</b> <div/>
                        <b style={{fontSize: "5.5vh", margin: "5vh"}}>bei</b> <div/>
                        <b style={{fontSize: "5.5vh", margin: "5vh"}}>StyleYourBox</b> <div/>
                    </div>
                </div>

                <div style={{marginTop: "3vh"}}>
                    <div style={{height: "3vh", backgroundColor: "#f5f5f5", textAlign: "center"}}>
                        {(() => {

                            const bubbles = []

                            for(var i = 0; i < 4; i++)
                            {
                                
                                if(i == index)
                                {
                                    bubbles.push(
                                        <button style={{width: "3vh", height: "3vh", marginLeft: "1vh", marginRight: "1vh", borderRadius: "1vh", border: "none", outline: "0", backgroundColor: "blue"}}/>
                                    );
                                }
                                else
                                {
                                    bubbles.push(
                                        <button style={{width: "3vh", height: "3vh", marginLeft: "1vh", marginRight: "1vh", borderRadius: "1vh", border: "none", outline: "0", backgroundColor: "#d9d9d9"}}/>
                                    );
                                }

                            }

                            return bubbles;

                        })()}
                    </div>
                </div>

                <AutoPlaySwipeableViews index={index} onChangeIndex={e => setIndex(e)}>

                    <div style={{height: "70vh", width: "100vw", float: "left"}}>
                        <div className="mimg1"/>
                    </div>

                    <div style={{height: "70vh", width: "100vw", float: "left"}}>
                        <div className="mimg2"/>
                    </div>

                    <div style={{height: "70vh", width: "100vw", float: "left"}}>
                        <div className="mimg3"/>
                    </div>

                    <div style={{height: "70vh", width: "100vw", float: "left"}}>
                        <div className="mimg4"/>
                    </div>

                </AutoPlaySwipeableViews>

                <div style={{textAlign: "center"}}>
                    <button style={{width: "95vw", height: "6vh", fontSize: "2.5vh", border: "none", borderRadius: "4vh", backgroundColor: "#9ca5ff"}} onClick={() => window.location.replace("../products/weinbox")}>Gestalte jetzt deine eigene Weinbox</button>
                </div>

                <div style={{height: "21vh", backgroundColor: "#3d3d3d", marginTop: "5vh", textAlign: "center"}}>

                    <button style={{marginTop: "1vh", height: "4vh", width: "80vw", border: "none", borderRadius: "1vh", fontSize: "2.5vh", backgroundColor: "#636363", color: "#dbdbdb"}} onClick={() => window.open("https://www.antagon24.de/impressum/")}>
                        Impressum
                    </button><div/>

                    <button style={{marginTop: "1vh", height: "4vh", width: "80vw", border: "none", borderRadius: "1vh", fontSize: "2.5vh", backgroundColor: "#636363", color: "#dbdbdb"}} onClick={() => window.open("https://www.antagon24.de/datenschutz/")}>
                        Datenschutz
                    </button><div/>

                    <button style={{marginTop: "1vh", height: "4vh", width: "80vw", border: "none", borderRadius: "1vh", fontSize: "2.5vh", backgroundColor: "#636363", color: "#dbdbdb"}} onClick={() => window.open("https://www.antagon24.de/agb/")}>
                        AGB
                    </button><div/>

                    <button style={{marginTop: "1vh", height: "4vh", width: "80vw", border: "none", borderRadius: "1vh", fontSize: "2.5vh", backgroundColor: "#636363", color: "#dbdbdb"}} onClick={() => window.open("http://styleyourbox.de/contact")}>
                        Kontakt
                    </button><div/>

                </div>

                


            </div>
        </MobileView>

    </div>);
}

export default Home;
