import React, {useState} from 'react';
import './products.css';
import './Home.css';
import {
    BrowserView,
    MobileView
  } from "react-device-detect";



function Products() 
{
  //Backend-Code für das Design
  function startButton() // Zur Startseite gehen
  {
      window.location.replace("../");
  }

  function productsButton() // Zur Seite "Produkte" gehen
  {
      window.location.replace("../products");
  }

  function contactButton() // Zur Seite "Kontakt" gehen
  {
      window.location.replace("../contact");
  }

  function toProduct1()
  {
    window.location.replace("../products/weinbox");
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [selfdesign, setSelfdesign] = useState(true);

    // React.Js Webdesign Code
    return (
        <div>
            <BrowserView>
                <div className="Header Center">
                    <div id="teil1"/>
                    <div id="teil2">
                        <button className="HeaderButton" onClick={startButton}>Startseite</button>
                        <button className="HeaderButton" onClick={productsButton}>Produkte</button>
                        <button className="HeaderButton" onClick={contactButton}>Kontakt</button>
                    </div>
                </div>
                <div className="Center"><p className="ModuleTitle">Unsere Produkte</p></div>
                <div className="TitleDevider"/>

                <div className="ptabHold">
                    <button className="ptabButtons" id={selfdesign? "this" : "other"} onMouseDown={() => setSelfdesign(true)}>Selbst gestalten</button>

                    <button className="ptabButtons" id={selfdesign? "other" : "this"} onMouseDown={() => setSelfdesign(false)}>Fertige Boxen</button>
                </div>

                {(() => {

                    if(selfdesign)
                    {
                        return(
                            <div>

                                <div className="pBox">
                                    <div className="pInfo">
                                        <h className="pBoxT">Weinbox</h> <div/>
                                        <h className="pBoxST">380mm x 118mm</h> <div/>
                                        <button className="pPrice">
                                            Ab 24,49€
                                        </button>
                                        <button className="pbBuy" onClick={toProduct1}>
                                            Jetzt gestalten
                                        </button>
                                    </div>
                                    <div className="vWeinbox"/>
                                </div>
                                
                            </div>
                        );
                    }
                    else
                    {
                        return(
                            <div style={{textAlign: "center"}}>
                                <h style={{fontSize: "5vh", color: "#d4d4d4"}}>Hier ist nichts zu sehen.</h>
                            </div>
                        );
                    }

                })()}
                
            </BrowserView>

            <MobileView>
                <div>
                    <div>
                        <div className="MobileLogo"/>
                        <div className="MobileSubheader">
                            <button className="MobileSubheaderButton" onClick={startButton}>Startseite</button>
                            <button className="MobileSubheaderButton" onClick={productsButton}>Produkte</button>
                            <button className="MobileSubheaderButton" onClick={contactButton}>Kontakt</button>
                        </div>

                        <div className="TitleDevider" style={{height: "2.5vh"}}/>

                        <div className="Center"><h className="MobileModuleTitle">Unsere Produkte</h></div>
                        
                        <div className="TitleDevider" style={{height: "2.5vh"}}/>

                        <div className="ptabHold">
                            <button className="ptabButtonsMo" id={selfdesign? "this" : "other"} onMouseDown={() => setSelfdesign(true)}>Selbst gestalten</button>

                            <button className="ptabButtonsMo" id={selfdesign? "other" : "this"} onMouseDown={() => setSelfdesign(false)}>Fertige Boxen</button>
                        </div>

                        {(() => {
                            if(selfdesign)
                            {
                                return(
                                    <div className="pBox_mob">

                                        <h className="pBoxT">Weinbox</h> <div/>
                                        <h className="pBoxST">380mm x 118mm</h> <div/>

                                        <div className="vWeinbox_m"/>

                                        <button className="pPrice" style={{marginLeft: "2vh"}}>
                                            Ab 24,49€
                                        </button>
                                        
                                        <button className="pbBuy" onClick={toProduct1} style={{marginLeft: "2vh"}}>
                                            Jetzt gestalten
                                        </button>
                                                
                                    </div>
                                );
                            }
                        })()}

                        <div className="TitleDevider"/>

                    </div>
                </div>
            </MobileView>


        </div>
    );
}

export default Products;
